<template>
  <div class="v-linear-chart  linear-chart">
    <canvas ref="line-chart"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
import breakpoints from "../../js/breakpoints";

Chart.register(...registerables);

export default {
  name: "LinearChart",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      labels: [],
      points: []
    };
  },
  watch: {
    items: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue?.length) {
          this.clearData();
          this.processData(newValue);
          if (this.$refs["line-chart"]) {
            this.init();
          }
        }
      }
    }
  },
  methods: {
    init() {
      if (window.outerWidth >= breakpoints["xs-max"]) {
        Chart.defaults.font.size = 12;
      } else {
        Chart.defaults.font.size = 9;
      }

      if (this.myChart) {
        this.myChart.destroy();
      }

      const data = {
        labels: this.labels,
        datasets: [
          {
            label: "",
            lineTension: 0.3,
            backgroundColor: "#1199f0",
            borderColor: "#1199f0",
            pointHitRadius: 30,
            data: this.points
          }
        ]
      };

      const config = {
        type: "line",
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              ticks: {
                precision: 0
              },
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      };
      this.myChart = new Chart(this.$refs["line-chart"], config);
    },
    // обработка полученых данных
    processData(data) {
      const start = data?.[0]?.date_from
        ? new Date(data[0].date_from).toLocaleString("ru", {
            hour: "2-digit",
            minute: "2-digit"
          })
        : "";
      this.labels.push(start);
      this.points.push(0);
      data.forEach(item => {
        this.labels.push(
          item.date_to
            ? new Date(item.date_to).toLocaleString("ru", {
                hour: "2-digit",
                minute: "2-digit"
              })
            : ""
        );
        this.points.push(item.count);
      });
    },
    clearData() {
      this.labels = [];
      this.points = [];
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
.linear-chart {
  height: 300px;
}
</style>
