var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-linear-chart  linear-chart"
  }, [_c('canvas', {
    ref: "line-chart"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }