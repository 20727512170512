<template>
  <section v-loading="loading" class="steps">
    <div class="steps__title">
      <router-link :to="{ name: 'Employee' }" class="steps__title-link">
        <img src="@/assets/icons/icon-arrow.svg" alt="" />
      </router-link>
      <PageTitle text="Назад к исполнителю" />
    </div>
    <div class="steps__body">
      <div class="steps__props">
        <div class="steps__prop">
          <div class="steps__prop-name">Исполнитель:</div>
          <div class="steps__prop-value">{{ employeeName }}</div>
        </div>
        <div class="steps__prop">
          <div class="steps__prop-name">Проект:</div>
          <div class="steps__prop-value">{{ projectName }}</div>
        </div>
      </div>
      <div class="steps__tools">
        <div class="steps__action">
          <button
            @click="getData(datePrev)"
            class="steps__link  steps__link--prev  reset-button"
            type="button"
          >
            Предыдущий день
          </button>
          <div class="steps__date">{{ dateText }}</div>
          <button
            @click="getData(dateNext)"
            class="steps__link  steps__link--next  reset-button"
            type="button"
          >
            Следующий день
          </button>
        </div>
        <div
          v-if="employeeSteps && employeeSteps.total === 0"
          class="steps__total"
        >
          Кол-во шагов:
          <div class="steps__total-value">{{ employeeSteps.total }}</div>
        </div>
      </div>
      <div
        v-if="employeeSteps && employeeSteps.data && employeeSteps.data.length"
        class="steps__chart"
      >
        <linear-chart :items="employeeSteps.data" />
      </div>
      <div v-else class="steps__empty">
        <img
          class="steps__empty-img"
          src="@/assets/icons/icon-empty-steps.svg"
          alt=""
        />
        <div class="steps__empty-text">
          Недостаточно данных для построения графика
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageTitle from "../components/elements/PageTitle";
import LinearChart from "../components/blocks/LinearChart";

export default {
  name: "Steps",
  components: {
    PageTitle,
    LinearChart
  },
  data() {
    return {
      loading: false,
      date: ""
    };
  },
  computed: {
    employeeSteps() {
      return this.$store.state.Employee.employeeSteps;
    },
    employeeData() {
      return this.$store.state.Employee.employeeData;
    },
    projectData() {
      return this.$store.state.Projects.projectData;
    },
    employeeName() {
      return this.employeeData ? this.employeeData.name : "";
    },
    projectName() {
      return this.projectData ? this.projectData.title : "";
    },
    dateText() {
      if (this.date) {
        return new Date(this.date).toLocaleString("ru").substr(0, 10);
      }
    },
    datePrev() {
      return this.date
        ? new Date(
            new Date(this.date).getTime() - 24 * 60 * 60 * 1000
          ).toISOString()
        : "".substr(0, 10);
    },
    dateNext() {
      return this.date
        ? new Date(
            new Date(this.date).getTime() + 24 * 60 * 60 * 1000
          ).toISOString()
        : "".substr(0, 10);
    }
  },
  methods: {
    getData(date) {
      this.loading = true;
      Promise.all([
        this.$route.params.id && !this.employeeData
          ? this.$store.dispatch("Employee/getEmployee", {
              id: this.$route.params.id
            })
          : null,
        this.$route.params.projectId && !this.projectData
          ? this.$store.dispatch("Projects/getProject", {
              id: this.$route.params.projectId
            })
          : null,
        this.$store.dispatch("Employee/getEmployeeSteps", {
          params: {
            vacancy_id: this.$route.params.vacancyId,
            date
          }
        })
      ]).then(() => {
        this.date = date;
        this.loading = false;
      });
    }
  },
  created() {
    let date = this.$route.query.date;
    this.getData(date);
  },
  destroyed() {
    this.$store.commit("Employee/setData", {
      label: "employeeSteps",
      data: null
    });
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.steps {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.04);
      border-radius: 6px;
      background: #fff;
      width: 2.8rem;
      height: 2.8rem;
      margin-right: 1rem;
      padding-right: 0.2rem;
      transition: 0.3s ease-out;
      cursor: pointer;

      &:hover {
        box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.44);
      }
    }
  }
}

.steps__body {
  flex-grow: 1;
  padding: 24px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.steps__props {
  margin-bottom: 40px;
}

.steps__prop {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 19px;

  &:not(:last-child) {
    margin-bottom: 14px;
  }
}

.steps__prop-name {
  color: #676973;
  margin-right: 6px;
}

.steps__prop-value {
  font-weight: 600;
  color: #31333b;
}

.steps__tools {
  display: flex;
  align-items: center;
}

.steps__action {
  display: flex;
  align-items: center;
}

.steps__link {
  font-size: 12px;
  line-height: 16px;
  color: #1199f0;
  display: flex;
  align-items: center;
  transition-duration: 300ms;
  @include no-touch {
    &:hover {
      opacity: 0.7;
    }
  }
}

.steps__link--prev {
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    border-left: 1px solid;
    border-top: 1px solid;
    transform: rotate(-45deg);
    margin-right: 8px;
  }
}

.steps__link--next {
  &::after {
    content: "";
    width: 5px;
    height: 5px;
    border-right: 1px solid;
    border-top: 1px solid;
    transform: rotate(45deg);
    margin-left: 8px;
  }
}

.steps__date {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #31333b;
  margin: 0 12px;
}

.steps__total {
  margin-left: auto;
  font-size: 14px;
  line-height: 19px;
  color: #676973;
  display: flex;
  align-items: center;
}

.steps__total-value {
  font-weight: 600;
  margin-left: 6px;
  color: #1199f0;
}

.steps__chart {
  margin-top: 32px;
}

.steps__empty {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.steps__empty-text {
  margin-top: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #a9a9a9;
}

@media (max-width: $screen-sm-max) {
  .steps__title {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: $screen-xs-max) {
  .steps__body {
    padding: 18px 12px;
  }
  .steps__props {
    margin-bottom: 26px;
  }
  .steps__prop {
    flex-direction: column;
    align-items: flex-start;
  }
  .steps__prop-name {
    margin-bottom: 2px;
  }
  .steps__tools {
    flex-direction: column;
    align-items: flex-start;
  }
  .steps__action {
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 26px;
  }
  .steps__date {
    margin: 0;
    margin-bottom: 8px;
    order: -10;
    width: 100%;
  }
  .steps__total {
    margin-left: 0;
  }
  .steps__chart {
    margin-top: 26px;
  }
  .steps__empty-img {
    width: 50px;
    height: 50px;
  }
  .steps__empty-text {
    margin-top: 20px;
    width: 160px;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
